import React from "react";

import iconComment from "../../images/icon-comment.png";
import ChatButton from "./chatBtn";

const ClaimButton = () => {
  let chatBtn;
  setTimeout(() => {
    chatBtn = document.querySelector("#zsiqbtn");
  }, 2000);

  const openChatHandler = () => {
    chatBtn.click();
  };
  return (
    <>
      <div
        className="btn btn-claim modal-btn"
        id="btn-claim"
        onClick={openChatHandler}
        onKeyDown={openChatHandler}
        role="button"
        tabIndex={0}
      >
        <figure className="mb-0 icon-comment">
          <img src={iconComment} alt="Icon Comment" className="img-fluid" />
        </figure>
        <span className="text-uppercase">
          <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
        </span>
      </div>
    </>
  );
};

export default ClaimButton;
