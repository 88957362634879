import React from "react"

import Layout from "../../components/layoutEs"
import Seo from "../../components/seoEs"
import Landing from "../../components/landing/landingEs"
import CampaignClosed from "../../components/campaign-closed/campaignClosedEs"

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Seo title="Home | Seguros Snickers" lang="es" />
        {/* Campaign is On */}
        <Landing />
        {/* Campaign Closed */}
        {/* <CampaignClosed /> */}
      </Layout>
    </>
  )
}

export default IndexPage
