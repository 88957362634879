import React from "react";
import ChatButton from "../chatBtnEs";
import iconEarlyDeath from "../../../images/es/icon-early-death.png";

const EarlyDeathTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="early-death"
        role="tabpanel"
        aria-labelledby="early-death-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-early-death.png"
              alt="Early Death"
              quality={100}
            /> */}
            <img src={iconEarlyDeath} alt="Early Death" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Early Death</h3> */}
          <p>
            ¿Fuiste el primero en morir de los 100 jugadores que había en el
            Battle Royale? ¿Te mató el jefe del tutorial antes de que sacaras tu
            arma? ¿Moriste incluso antes de que tu equipo le entrara a la pelea?
            Cubrimos muertes prematuras en videojuegos de todo tipo, ¡así que
            convierte esa pena que pasaste en un Snickers!
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarlyDeathTab;
