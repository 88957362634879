import React from "react"
import ClaimButton from "./claimButtonEs"

const Claim = () => {
  return (
    <>
      <section className="text-center">
        <ClaimButton />
      </section>
    </>
  )
}

export default Claim
