import React from "react";
import ChatButton from "../chatBtnEs";
import iconLootLoss from "../../../images/es/icon-loot-loss.png";

const LootLossTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="loot-loss"
        role="tabpanel"
        aria-labelledby="loot-loss-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-loot-loss.png"
              alt="Loot Loss"
              quality={100}
            /> */}
            <img src={iconLootLoss} alt="Loot Loss" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Loot Loss</h3> */}
          <p>
            Típico que por fin consigues ese ítem legendario y lo pierdes al
            instante por caer al precipicio mientras celebrabas tu buena suerte.
            Menos mal que ofrecemos beneficios a personas que pierden objetos de
            gran valor imaginario porque el hambre los hizo hacer algo realmente
            tonto.
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LootLossTab;
