import React from "react";

const FooterNav = () => {
  return (
    <nav>
      <ul className="nav justify-content-center text-uppercase">
        <li className="nav-link">
          <a
            href="https://www.mars.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacidad
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="/Términos_y_condiciones_Seguros_Snickers_15_Junio.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            REGULACIÓN
            <span className="visually-hidden">Document (136kb)</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/cookies-english"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookies
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        {/* <li className="nav-link">
            <a
              href="https://www.mars.com/about/policies-and-practices/ca-supply-chain-transparency-act"
              target="_blank"
              rel="noopener noreferrer"
            >
              CA Supply Chain Transparency
              <span className="visually-hidden">opens in a new window</span>
            </a>
          </li> */}
        <li className="nav-link">
          <a
            href="https://www.mars.com/accessibility"
            target="_blank"
            rel="noopener noreferrer"
          >
            Accesibilidad
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        {/* <li className="nav-link">
            <a
              href="https://www.mars.com/about/policies-and-practices/modern-slavery-act"
              target="_blank"
              rel="noopener noreferrer"
            >
              Modern Slavery Act
              <span className="visually-hidden">opens in a new window</span>
            </a>
          </li>
          <li className="nav-link">
            <a
              href="https://www.mars.com/mars-incorporated-adchoices-united-states"
              target="_blank"
              rel="noopener noreferrer"
            >
              ADChoices
              <span className="visually-hidden">opens in a new window</span>
            </a>
          </li> */}
        <li className="nav-link">
          <a
            href="https://www.mars.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contáctanos
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
