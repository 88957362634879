import React from "react";
import ChatButton from "../chatBtnEs";
import iconRandomBlunders from "../../../images/es/icon-random-blunders.png";

const OtherBlundersTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="other-blunders"
        role="tabpanel"
        aria-labelledby="other-blunders-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-random-blunders.png"
              alt="Other Blunders"
              quality={100}
            /> */}
            <img
              src={iconRandomBlunders}
              alt="Other Blunders"
              className="img-fluid"
            />
          </figure>
          {/* <h3 className="text-uppercase">Other Blunders</h3> */}
          <p>
            ¿Cometiste un error jugando por el hambre y no está cubierto por una
            de nuestras pólizas? ¿O tal vez no estás seguro cuál de nuestras
            pólizas aplica y ya te cansaste de intentar? ¡Nuestros agentes
            también puede ayudarte con eso!
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherBlundersTab;
