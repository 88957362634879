import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import oxxo from "../../images/es/logo-oxxo-mr.png";

// import snickersHungerInsuranceLogo from "../../images/snickers-hunger-insurance-pt.png"

const Logo = () => {
  return (
    <>
      <div className="d-flex align-items-end">
        <Link className="d-inline-block" to="/es">
          <figure className="mb-0">
            <StaticImage
              src="../../images/header-snickers-hunger-insurance-es.png"
              alt="Snickers Logo"
              quality={100}
            />
          </figure>
        </Link>
        <h4 className="text-secondary">EXCLUSIVO&nbsp;EN:</h4>
        <img className="oxxo-logo" src={oxxo} alt="OXXO Logo" />
      </div>
    </>
  );
};

export default Logo;
